import React, { useState, useEffect } from 'react';
import { 
  Card, 
  Title, 
  Text, 
  Grid, 
  Col, 
  Metric, 
  Button,
  Select,
  SelectItem 
} from "@tremor/react";
import { api } from '../services/api';
import { TokenCard } from './statistics/TokenCard';
import { formatNumber } from './utils/format';
import { Cog6ToothIcon } from '@heroicons/react/24/outline';

const Statistics = () => {
  // States
  const [stats, setStats] = useState({
    totalTrades: 0,
    totalUsdtSpent: 0,
    totalUsdtReceived: 0,
    currentPortfolioValue: 0,
    totalProfitLoss: 0,
    tokens: []
  });
  
  const [lastSellPrices, setLastSellPrices] = useState({});
  const [notificationSettings, setNotificationSettings] = useState(() => {
    const savedSettings = localStorage.getItem('notificationSettings');
    return savedSettings ? JSON.parse(savedSettings) : {
      enabled: true,
      dcaThreshold: 5,
      dcaAlertInterval: 60,
      profitThreshold: 5,
      profitAlertInterval: 60,
      lastSellDropThreshold: 5,
      lastSellAlertInterval: 60
    };
  });
  
  const [lastAlertTimes, setLastAlertTimes] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [reloadInterval, setReloadInterval] = useState(60);
  const [intervalId, setIntervalId] = useState(null);
  const [tokenFilter, setTokenFilter] = useState('all');

  // Fetch data function
  const fetchData = async () => {
    try {
      setLoading(true);
      const [statsResponse, lastSellsResponse] = await Promise.all([
        api.getStatistics(),
        api.getLastSellPrices()
      ]);

      setStats(statsResponse.data);
      setLastSellPrices(lastSellsResponse.data);
      setError(null);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(error.response?.data?.message || 'Error fetching data');
    } finally {
      setLoading(false);
    }
  };

  // Filter tokens function
  const getFilteredTokens = (tokens) => {
    switch (tokenFilter) {
      case 'holding':
        return tokens.filter(token => token.remainingTokens > 0);
      case 'sold':
        return tokens.filter(token => token.remainingTokens === 0);
      default:
        return tokens;
    }
  };

  // Alert handler
  const handleAlert = async (alert, tokenSymbol) => {
    try {
      if (notificationSettings.enabled) {
        const response = await api.sendNotification({
          message: alert.message,
          symbol: tokenSymbol,
          type: alert.type
        });
        
        if (response.data.success) {
          setLastAlertTimes(prev => ({
            ...prev,
            [`${tokenSymbol}_${alert.type}`]: Date.now()
          }));
        }
      }
    } catch (error) {
      console.error('Error sending notification:', error);
    }
  };

  // Settings handler
  const handleSaveSettings = (newSettings) => {
    setNotificationSettings(newSettings);
    localStorage.setItem('notificationSettings', JSON.stringify(newSettings));
  };

  // Auto refresh effect
  useEffect(() => {
    fetchData();

    if (intervalId) {
      clearInterval(intervalId);
    }

    if (reloadInterval > 0) {
      const newIntervalId = setInterval(fetchData, reloadInterval * 1000);
      setIntervalId(newIntervalId);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [reloadInterval]);

  // Render
  return (
    <div className="p-4 space-y-6">
      {/* Control Panel */}
      <Card>
        <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between gap-4">
          <div className="flex flex-col sm:flex-row sm:items-center gap-4">
            <Text>Auto Refresh Interval:</Text>
            <div className="flex items-center gap-2">
              <input
                type="number"
                min="0"
                value={reloadInterval}
                onChange={(e) => setReloadInterval(Number(e.target.value))}
                className="w-24 px-2 py-1 border rounded-md focus:ring-2 focus:ring-blue-500"
              />
              <Text>seconds</Text>
            </div>
            <Text className="text-gray-500 text-sm">
              (Set to 0 to disable auto refresh)
            </Text>
          </div>
          
          <div className="flex flex-col sm:flex-row sm:items-center gap-4">
            <div className="flex items-center gap-2">
              <Text className="whitespace-nowrap">Filter:</Text>
              <Select 
                value={tokenFilter}
                onValueChange={setTokenFilter}
                className="min-w-[200px] bg-white"
              >
                <SelectItem value="all" className="bg-white hover:bg-gray-100">
                  All Tokens
                </SelectItem>
                <SelectItem value="holding" className="bg-white hover:bg-gray-100">
                  Holding Tokens
                </SelectItem>
                <SelectItem value="sold" className="bg-white hover:bg-gray-100">
                  Sold Tokens
                </SelectItem>
              </Select>
            </div>

            <div className="flex gap-2">
              <Button 
                onClick={fetchData}
                className="whitespace-nowrap"
              >
                Refresh Now
              </Button>
            </div>
          </div>
        </div>
      </Card>

      {/* Overall Statistics */}
      <Card>
        <Title>Overall Statistics</Title>
        <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
          <div>
            <Text>Total Transactions</Text>
            <Metric>{formatNumber(stats.totalTrades, 0)}</Metric>
          </div>
          <div>
            <Text>Total USDT Invested</Text>
            <Metric>{formatNumber(stats.totalUsdtSpent)} USDT</Metric>
          </div>
          <div>
            <Text>Total USDT Received</Text>
            <Metric>{formatNumber(stats.totalUsdtReceived)} USDT</Metric>
          </div>
          <div>
            <Text>Current Portfolio Value</Text>
            <Metric>{formatNumber(stats.currentPortfolioValue)} USDT</Metric>
          </div>
        </div>
        <div className="mt-4 p-4 bg-gray-50 rounded-lg">
          <Text>Total Profit/Loss (including unrealized)</Text>
          <div className="flex items-center gap-2">
            <Metric className={stats.totalProfitLoss >= 0 ? 'text-green-600' : 'text-red-600'}>
              {(stats.totalProfitLoss >= 0 ? '+' : '') + formatNumber(stats.totalProfitLoss)} USDT
            </Metric>
            <Text>
              ({stats.tokens.filter(t => t.remainingTokens > 0).length} holding / {stats.tokens.length} total)
            </Text>
          </div>
        </div>
      </Card>

      {/* Token Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
        {getFilteredTokens(stats.tokens || [])
          .sort((a, b) => Math.abs(b.totalProfitLoss) - Math.abs(a.totalProfitLoss))
          .map((token) => (
            <TokenCard 
              key={token.symbol}
              token={token}
              lastSellPrice={lastSellPrices[`${token.symbol}USDT`]}
              notificationSettings={notificationSettings}
              lastAlertTimes={lastAlertTimes}
              onAlert={(alert) => handleAlert(alert, token.symbol)}
            />
          ))}
      </div>

      {/* Empty State */}
      {getFilteredTokens(stats.tokens || []).length === 0 && (
        <Card>
          <div className="text-center py-6">
            <Text>
              {tokenFilter === 'holding' 
                ? 'No tokens currently being held'
                : tokenFilter === 'sold'
                ? 'No fully sold tokens'
                : 'No tokens found'}
            </Text>
          </div>
        </Card>
      )}

      {/* Loading Indicator */}
      {loading && (
        <div className="fixed bottom-4 right-4 bg-blue-500 text-white px-4 py-2 rounded-md 
                      shadow-lg animate-pulse">
          Refreshing...
        </div>
      )}
    </div>
  );
};

export default Statistics;