// components/Settings.jsx
import React, { useState, useEffect } from 'react';
import { Card, Title, Divider, Text } from "@tremor/react";
import { api } from '../services/api';

export const Settings = () => {
  // Thêm botRunning state
  const [botRunning, setBotRunning] = useState(false);
  
  const [settings, setSettings] = useState(() => {
    const defaultSettings = {
      // Basic settings
      googleSheetId: '',
      telegramBotToken: '',
      telegramChatId: '',
      scanInterval: 5,
      notificationDelay: 5,
      enableTelegram: true,
      
      // Proxy settings
      enableProxy: false,
      proxyUrl: '',
      proxyPort: 443,

      // Notification settings
      notificationsEnabled: true,
      dcaThreshold: 5,
      dcaAlertInterval: 60,
      profitThreshold: 5,
      profitAlertInterval: 60,
      lastSellDropThreshold: 5,
      lastSellAlertInterval: 60,
      
      // Time range settings  
      timeRange: {
        type: 'minutes',
        minutes: 10,
        startDate: '',
        endDate: ''
      }
    };

    try {
      const savedSettings = localStorage.getItem('botSettings');
      if (savedSettings) {
        const parsed = JSON.parse(savedSettings);
        return {
          ...defaultSettings,
          ...parsed,
          timeRange: {
            ...defaultSettings.timeRange,
            ...(parsed.timeRange || {})
          }
        };
      }
      return defaultSettings;
    } catch (error) {
      console.error('Error loading settings from localStorage:', error);
      return defaultSettings;
    }
  });

  const [loading, setLoading] = useState(false);
  const [loadingConfig, setLoadingConfig] = useState(false);
  const [message, setMessage] = useState({ text: '', type: '' });

  // Add useEffect to check bot status
  useEffect(() => {
    const checkBotStatus = async () => {
      try {
        const response = await api.getStatus();
        setBotRunning(response.data.isRunning);
      } catch (error) {
        console.error('Error checking bot status:', error);
      }
    };
    
    checkBotStatus();
    const interval = setInterval(checkBotStatus, 5000);
    return () => clearInterval(interval);
  }, []);

  // Message timeout effect
  useEffect(() => {
    if (message.text) {
      const timer = setTimeout(() => {
        setMessage({ text: '', type: '' });
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [message]);

  const handleBasicSettingsChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : 
                    type === 'number' ? parseInt(value) || value : 
                    value;
                    
    setSettings(prevSettings => ({
      ...prevSettings,
      [name]: newValue
    }));

    // Save to localStorage
    const newSettings = {
      ...settings,
      [name]: newValue
    };
    localStorage.setItem('botSettings', JSON.stringify(newSettings));
  };

  const handleTimeRangeValueChange = (e) => {
    const { name, value } = e.target;
    setSettings(prev => ({
      ...prev,
      timeRange: {
        ...prev.timeRange,
        [name]: value
      }
    }));

    // Save to localStorage
    localStorage.setItem('botSettings', JSON.stringify({
      ...settings,
      timeRange: {
        ...settings.timeRange,
        [name]: value
      }
    }));
  };

  const handleTimeRangeChange = (type) => {
    setSettings(prev => ({
      ...prev,
      timeRange: {
        ...prev.timeRange,
        type
      }
    }));

    // Save to localStorage
    localStorage.setItem('botSettings', JSON.stringify({
      ...settings,
      timeRange: {
        ...settings.timeRange,
        type
      }
    }));
  };

  const loadCurrentSettings = async () => {
    setLoadingConfig(true);
    setMessage({ text: '', type: '' });

    try {
      const response = await api.getCurrentSettings();

      if (response.data) {
        const newSettings = {
          googleSheetId: response.data.googleSheetId || '',
          telegramBotToken: response.data.telegramBotToken || '',
          telegramChatId: response.data.telegramChatId || '',
          scanInterval: response.data.scanInterval || 5,
          notificationDelay: response.data.notificationDelay || 5,
          enableTelegram: response.data.enableTelegram,
          enableProxy: response.data.enableProxy,
          proxyUrl: response.data.proxyUrl || '',
          proxyPort: response.data.proxyPort || 443,
          notificationsEnabled: response.data.notificationsEnabled,
          dcaThreshold: response.data.dcaThreshold || 5,
          dcaAlertInterval: response.data.dcaAlertInterval || 60,
          profitThreshold: response.data.profitThreshold || 5,
          profitAlertInterval: response.data.profitAlertInterval || 60,
          lastSellDropThreshold: response.data.lastSellDropThreshold || 5,
          lastSellAlertInterval: response.data.lastSellAlertInterval || 60,
          timeRange: {
            type: response.data.timeRangeType || 'minutes',
            minutes: response.data.tradeHistory || 10,
            startDate: response.data.timeRangeStartDate || '',
            endDate: response.data.timeRangeEndDate || ''
          }
        };

        setSettings(newSettings);
        localStorage.setItem('botSettings', JSON.stringify(newSettings));
        setMessage({ text: 'Settings loaded successfully', type: 'success' });
      }
    } catch (error) {
      setMessage({ 
        text: error.response?.data?.message || 'Error loading settings',
        type: 'error'
      });
    } finally {
      setLoadingConfig(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (botRunning) {
      setMessage({
        text: 'Please stop the bot before updating settings',
        type: 'error'
      });
      return;
    }

    setLoading(true);
    setMessage({ text: '', type: '' });

    try {
      await api.updateSettings({
        googleSheetId: settings.googleSheetId,
        telegramBotToken: settings.telegramBotToken,
        telegramChatId: settings.telegramChatId,
        scanInterval: settings.scanInterval,
        notificationDelay: settings.notificationDelay,
        enableTelegram: settings.enableTelegram,
        enableProxy: settings.enableProxy,
        proxyUrl: settings.proxyUrl,
        proxyPort: settings.proxyPort,
        notificationsEnabled: settings.notificationsEnabled,
        dcaThreshold: settings.dcaThreshold,
        dcaAlertInterval: settings.dcaAlertInterval,
        profitThreshold: settings.profitThreshold,
        profitAlertInterval: settings.profitAlertInterval,
        lastSellDropThreshold: settings.lastSellDropThreshold,
        lastSellAlertInterval: settings.lastSellAlertInterval,
        timeRangeType: settings.timeRange.type,
        timeRangeStartDate: settings.timeRange.startDate,
        timeRangeEndDate: settings.timeRange.endDate,
        tradeHistory: settings.timeRange.minutes
      });

      setMessage({ text: 'Settings updated successfully', type: 'success' });
    } catch (error) {
      setMessage({ 
        text: error.response?.data?.message || 'Error updating settings',
        type: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  // Render sections
  const renderToggleSwitch = (label, name, checked) => (
    <div className="flex items-center justify-between">
      <span className="text-sm font-medium text-gray-700">{label}</span>
      <label className="relative inline-flex items-center cursor-pointer">
        <input
          type="checkbox"
          name={name}
          checked={checked}
          onChange={handleBasicSettingsChange}
          className="sr-only peer"
        />
        <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 
          peer-focus:ring-blue-300 rounded-full peer 
          peer-checked:after:translate-x-full peer-checked:after:border-white 
          after:content-[''] after:absolute after:top-[2px] after:left-[2px] 
          after:bg-white after:border-gray-300 after:border after:rounded-full 
          after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600">
        </div>
      </label>
    </div>
  );

  const renderNumberInput = (label, name, value, min = 1, step = 1) => (
    <div>
      <Text className="mb-2">{label}</Text>
      <input
        type="number"
        name={name}
        value={value}
        onChange={handleBasicSettingsChange}
        min={min}
        step={step}
        className="w-full px-3 py-2 rounded-md border border-gray-300 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
      />
    </div>
  );

  const renderAlertSettings = (title, thresholdName, intervalName) => (
    <div className="space-y-3">
      <Text className="font-medium text-gray-900">{title}</Text>
      <div className="bg-gray-50 rounded-lg p-4 space-y-4">
        {renderNumberInput(
          'Threshold (%)',
          thresholdName,
          settings[thresholdName],
          0,
          0.1
        )}
        {renderNumberInput(
          'Alert Interval (minutes)',
          intervalName,
          settings[intervalName]
        )}
      </div>
    </div>
  );

   return (
    <div className="p-4">
      <Card>
        {/* Header */}
        <div className="flex justify-between items-center">
          <Title>Configuration Settings</Title>
          <div className="flex space-x-4">
            <button
              onClick={loadCurrentSettings}
              disabled={loadingConfig}
              className={`px-4 py-2 rounded-md shadow-sm text-sm font-medium text-white 
                ${loadingConfig ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700'}`}
            >
              {loadingConfig ? 'Loading...' : 'Load Current Settings'}
            </button>
            <button
              type="button"
              onClick={handleSubmit}
              disabled={loading || botRunning}
              className={`px-4 py-2 rounded-md shadow-sm text-sm font-medium text-white 
                ${loading || botRunning ? 'bg-gray-400 cursor-not-allowed' : 'bg-green-600 hover:bg-green-700'}`}
            >
              {loading ? 'Saving...' : botRunning ? 'Stop Bot to Save' : 'Save All Settings'}
            </button>
          </div>
        </div>

        {/* Warning when bot is running */}
        {botRunning && (
          <div className="mt-4 bg-yellow-100 text-yellow-700 p-4 rounded-md">
            ⚠️ Bot is currently running. Please stop the bot before updating settings.
          </div>
        )}

        {/* Messages */}
        {message.text && (
          <div className={`mt-4 p-3 rounded ${
            message.type === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'
          }`}>
            {message.text}
          </div>
        )}

        <form onSubmit={handleSubmit} className="mt-4 space-y-6">
          {/* Google Sheets Settings */}
          <div className="space-y-4">
            <Title>Google Sheets Settings</Title>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Google Sheet ID
              </label>
              <input
                type="text"
                name="googleSheetId"
                value={settings.googleSheetId}
                onChange={handleBasicSettingsChange}
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
          </div>

          <Divider />
		{/* Time Range Settings */}
<div className="space-y-4">
  <Title>Time Range Settings</Title>
  <div className="space-y-4">
    <div className="flex space-x-6">
      <label className="inline-flex items-center">
        <input
          type="radio"
          className="form-radio text-blue-600 h-4 w-4"
          checked={settings.timeRange.type === 'minutes'}
          onChange={() => handleTimeRangeChange('minutes')}
        />
        <span className="ml-2 text-sm text-gray-700">
          Last <input
            type="number"
            className="w-16 px-2 py-1 mx-1 inline-block border border-gray-300 rounded"
            value={settings.timeRange.minutes}
            onChange={e => handleTimeRangeValueChange({ target: { name: 'minutes', value: e.target.value }})}
            min="1"
          /> Minutes
        </span>
      </label>
      <label className="inline-flex items-center">
        <input
          type="radio"
          className="form-radio text-blue-600 h-4 w-4" 
          checked={settings.timeRange.type === 'dateRange'}
          onChange={() => handleTimeRangeChange('dateRange')}
        />
        <span className="ml-2 text-sm text-gray-700">Date Range</span>
      </label>
    </div>

    {settings.timeRange.type === 'dateRange' && (
      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">Start Date</label>
          <input
            type="datetime-local"
            name="startDate"
            value={settings.timeRange.startDate}
            onChange={handleTimeRangeValueChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">End Date</label>
          <input
            type="datetime-local"
            name="endDate"
            value={settings.timeRange.endDate}
            onChange={handleTimeRangeValueChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          />
        </div>
      </div>
    )}
  </div>
</div>
          {/* Telegram Settings */}
          <div className="space-y-4">
            <Title>Telegram Settings</Title>
            {renderToggleSwitch('Enable Telegram Notifications', 'enableTelegram', settings.enableTelegram)}

            {settings.enableTelegram && (
              <>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Telegram Bot Token
                  </label>
                  <input
                    type="text"
                    name="telegramBotToken"
                    value={settings.telegramBotToken}
                    onChange={handleBasicSettingsChange}
                    required={settings.enableTelegram}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Telegram Chat ID
                  </label>
                  <input
                    type="text"
                    name="telegramChatId"
                    value={settings.telegramChatId}
                    onChange={handleBasicSettingsChange}
                    required={settings.enableTelegram}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  />
                </div>
              </>
            )}
          </div>

          <Divider />

          {/* Price Alert Settings */}
          <div className="space-y-4">
            <Title>Price Alert Settings</Title>
            {renderToggleSwitch('Enable Price Alerts', 'notificationsEnabled', settings.notificationsEnabled)}

            {settings.notificationsEnabled && (
              <>
                {renderAlertSettings('DCA Alert Settings', 'dcaThreshold', 'dcaAlertInterval')}
                {renderAlertSettings('Take Profit Alert Settings', 'profitThreshold', 'profitAlertInterval')}
                {renderAlertSettings('Last Sell Price Alert Settings', 'lastSellDropThreshold', 'lastSellAlertInterval')}
              </>
            )}
          </div>

          <Divider />

          {/* Advanced Settings */}
<div className="space-y-4">
  <Title>Advanced Settings</Title>
  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
    <div>
      <label className="block text-sm font-medium text-gray-700">
        Scan Interval (minutes)
      </label>
      <input
        type="number"
        name="scanInterval"
        value={settings.scanInterval}
        onChange={handleBasicSettingsChange}
        min="1"
        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
      />
    </div>
    {settings.enableTelegram && (
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Notification Delay (seconds)
        </label>
        <input
          type="number"
          name="notificationDelay"
          value={settings.notificationDelay}
          onChange={handleBasicSettingsChange}
          min="1"
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
        />
      </div>
    )}
  </div>
</div>
<Divider />

{/* Proxy Settings */}
<div className="space-y-4">
  <Title>Proxy Settings</Title>
  <div className="flex items-center justify-between">
    <span className="text-sm font-medium text-gray-700">Enable Proxy Server</span>
    <label className="relative inline-flex items-center cursor-pointer">
      <input
        type="checkbox"
        name="enableProxy"
        checked={settings.enableProxy}
        onChange={handleBasicSettingsChange}
        className="sr-only peer"
      />
      <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 
        peer-focus:ring-blue-300 rounded-full peer 
        peer-checked:after:translate-x-full peer-checked:after:border-white 
        after:content-[''] after:absolute after:top-[2px] after:left-[2px] 
        after:bg-white after:border-gray-300 after:border after:rounded-full 
        after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600">
      </div>
    </label>
  </div>

  {settings.enableProxy && (
    <div className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Proxy Server URL
        </label>
        <input
          type="text"
          name="proxyUrl"
          value={settings.proxyUrl}
          onChange={handleBasicSettingsChange}
          placeholder="e.g., https://proxy.example.com"
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Proxy Server Port
        </label>
        <input
          type="number"
          name="proxyPort"
          value={settings.proxyPort}
          onChange={handleBasicSettingsChange}
          min="1"
          max="65535"
          placeholder="443"
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
        />
      </div>
    </div>
  )}
</div>
        </form>
      </Card>
    </div>
  );
};

export default Settings;