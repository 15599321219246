import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, NavLink, Navigate, useNavigate, useLocation, Outlet } from 'react-router-dom';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import Statistics from './components/Statistics';
import { Settings } from './components/Settings';
import { EnhancedLogs } from './components/EnhancedLogs';
import Login from './components/Login';
import { api } from './services/api';

const ProtectedRoutes = ({ children }) => {
  const token = localStorage.getItem('token');
  const location = useLocation();

  if (!token) {
    localStorage.setItem('redirectPath', location.pathname);
    return <Navigate to="/login" replace />;
  }

  return children;
};

// Layout component
const AppLayout = ({ 
  status, 
  loading, 
  handleStartBot, 
  handleStopBot, 
  handleLogout, 
  navigationItems, 
  renderNavLink 
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Control button component
  const ControlButtons = ({ isMobile = false }) => (
    <div className={isMobile ? "space-y-2 w-full" : "space-x-2"}>
      <button
        onClick={() => {
          handleStartBot();
          if (isMobile) setIsMenuOpen(false);
        }}
        disabled={loading || status.isRunning}
        className={`${isMobile ? 'w-full' : ''} px-4 py-2 rounded-md text-sm font-medium text-white transition-colors
          ${loading || status.isRunning
            ? 'bg-gray-400 cursor-not-allowed'
            : 'bg-green-600 hover:bg-green-700'}`}
      >
        {loading ? 'Processing...' : 'Start'}
      </button>
      <button
        onClick={() => {
          handleStopBot();
          if (isMobile) setIsMenuOpen(false);
        }}
        disabled={loading || !status.isRunning}
        className={`${isMobile ? 'w-full' : ''} px-4 py-2 rounded-md text-sm font-medium text-white transition-colors
          ${loading || !status.isRunning
            ? 'bg-gray-400 cursor-not-allowed'
            : 'bg-red-600 hover:bg-red-700'}`}
      >
        {loading ? 'Processing...' : 'Stop'}
      </button>
      <button
        onClick={() => {
          handleLogout();
          if (isMobile) setIsMenuOpen(false);
        }}
        className={`${isMobile ? 'w-full' : ''} px-4 py-2 rounded-md text-sm font-medium text-white bg-gray-600 hover:bg-gray-700`}
      >
        Logout
      </button>
    </div>
  );

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <nav className="bg-white shadow fixed top-0 left-0 right-0 z-50">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex justify-between h-16">
            {/* Logo and Desktop Menu */}
            <div className="flex items-center">
              <div className="flex-shrink-0 flex items-center">
                <h1 className="text-xl font-bold text-gray-800 whitespace-nowrap">
                  <span className="md:hidden">BNC Trans</span>
                  <span className="hidden md:inline">BNC Transactions</span>
                </h1>
              </div>

              {/* Desktop Navigation */}
              <div className="hidden md:flex ml-6 space-x-8">
                {navigationItems.map(item => renderNavLink(item))}
              </div>
            </div>

            {/* Control Buttons and Mobile Menu Button */}
            <div className="flex items-center space-x-4">
              {/* Desktop Control Buttons */}
              <div className="hidden md:flex items-center">
                <ControlButtons />
              </div>

              {/* Mobile Menu Button */}
              <button
                type="button"
                className="md:hidden inline-flex items-center justify-center p-2 rounded-md 
                  text-gray-400 hover:text-gray-500 hover:bg-gray-100 transition-colors"
                onClick={() => setIsMenuOpen(!isMenuOpen)}
              >
                <span className="sr-only">Toggle menu</span>
                {isMenuOpen ? (
                  <XMarkIcon className="block h-6 w-6" />
                ) : (
                  <Bars3Icon className="block h-6 w-6" />
                )}
              </button>
            </div>
          </div>

          {/* Mobile Menu */}
          <div 
            className={`${
              isMenuOpen 
                ? 'max-h-screen opacity-100 visible'
                : 'max-h-0 opacity-0 invisible'
            } md:hidden transform transition-all duration-200 ease-in-out overflow-hidden 
              absolute top-16 left-0 right-0 bg-white border-b border-gray-200`}
          >
            <div className="px-2 pt-2 pb-3 space-y-1">
              {/* Mobile Navigation */}
              {navigationItems.map(item => renderNavLink(item, true))}
              
              {/* Mobile Control Buttons */}
              <div className="px-3 py-2 space-y-2 border-t border-gray-200 mt-2 pt-4">
                <ControlButtons isMobile />
              </div>
            </div>
          </div>
        </div>
      </nav>

      {/* Main Content */}
      <main className="flex-1 max-w-7xl w-full mx-auto py-6 sm:px-6 lg:px-8 mt-16">
        <Outlet />
      </main>
    </div>
  );
};

function App() {
  const [status, setStatus] = useState({
    isRunning: false
  });
  const [loading, setLoading] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsAuthenticated(!!token);

    if (token) {
      if (location.pathname === '/') {
        navigate('/statistics');
      }
      
      const fetchStatus = async () => {
        try {
          const response = await api.getStatus();
          setStatus({ isRunning: response.data.isRunning });
        } catch (error) {
          console.error('Error fetching status:', error);
          if (error.response?.status === 401 || error.response?.status === 403) {
            setIsAuthenticated(false);
            localStorage.removeItem('token');
          }
        }
      };

      fetchStatus();
      const interval = setInterval(fetchStatus, 5000);
      return () => clearInterval(interval);
    }
  }, [location, navigate]);

  const handleStartBot = async () => {
    setLoading(true);
    try {
      const savedSettings = localStorage.getItem('botSettings');
      if (!savedSettings) {
        alert('Please configure bot settings first');
        return;
      }

      const settings = JSON.parse(savedSettings);
      const timeRange = settings.timeRange;
      
      await api.startBot(timeRange);
      setStatus({ isRunning: true });
    } catch (error) {
      console.error('Error starting bot:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleStopBot = async () => {
    setLoading(true);
    try {
      await api.stopBot();
      setStatus({ isRunning: false });
    } catch (error) {
      console.error('Error stopping bot:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
  };

  const navigationItems = [
    { path: '/statistics', label: 'Statistics' },
    { path: '/settings', label: 'Settings' },
    { path: '/logs', label: 'System Logs' }
  ];

  const renderNavLink = ({ path, label }, isMobile = false) => {
    const baseClasses = isMobile
      ? 'block px-3 py-2 rounded-md text-base font-medium'
      : 'inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium';
      
    const activeClasses = isMobile
      ? 'bg-blue-50 text-blue-600'
      : 'border-blue-500 text-blue-600';
      
    const inactiveClasses = isMobile
      ? 'text-gray-500 hover:bg-gray-50 hover:text-gray-700'
      : 'border-transparent text-gray-500 hover:text-gray-700';

    return (
      <NavLink
        key={path}
        to={path}
        className={({ isActive }) =>
          `${baseClasses} ${isActive ? activeClasses : inactiveClasses}`
        }
        end
      >
        {label}
      </NavLink>
    );
  };

  if (!isAuthenticated) {
    return <Login setIsAuthenticated={setIsAuthenticated} />;
  }

  return (
    <Routes>
      <Route path="/login" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
      <Route
        element={
          <ProtectedRoutes>
            <AppLayout
              status={status}
              loading={loading}
              handleStartBot={handleStartBot}
              handleStopBot={handleStopBot}
              handleLogout={handleLogout}
              navigationItems={navigationItems}
              renderNavLink={renderNavLink}
            />
          </ProtectedRoutes>
        }
      >
        <Route index element={<Navigate to="/statistics" replace />} />
        <Route path="/statistics" element={<Statistics />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/logs" element={<EnhancedLogs />} />
        <Route path="*" element={<Navigate to="/statistics" replace />} />
      </Route>
    </Routes>
  );
}

export default App;