import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: 'https://bnc.zxc.mom/api'
});

// Thêm interceptor để tự động gắn token vào header
axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Thêm interceptor để xử lý token hết hạn
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401 || error.response?.status === 403) {
      localStorage.removeItem('token');
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export const api = {
  // Auth endpoints mới
  login: (username, password) => 
    axiosInstance.post('/auth/login', { username, password }),
  register: (username, password) => 
    axiosInstance.post('/auth/register', { username, password }),

  // Các endpoints gốc
  getStatus: () => axiosInstance.get('/status'),
  startBot: (timeRange) => axiosInstance.post('/start', { timeRange }),
  stopBot: () => axiosInstance.post('/stop'),
  getSettings: () => axiosInstance.get('/settings'),
  getCurrentSettings: () => axiosInstance.get('/settings'),
  updateSettings: (settings) => axiosInstance.post('/settings', settings),
  getLogs: (params) => axiosInstance.get('/logs', { params }),
  clearLogs: (days) => axiosInstance.delete(`/logs`, { params: { days } }),
  getStatistics: () => axiosInstance.get('/statistics'),
  getLastSellPrices: () => axiosInstance.get('/statistics/last-sells'),
  sendNotification: (data) => axiosInstance.post('/notifications', data),
  get: (url, config) => axiosInstance.get(url, config),
  post: (url, data, config) => axiosInstance.post(url, data, config),

  // Tiện ích cho interceptors
  setAuthToken: (token) => {
    if (token) {
      localStorage.setItem('token', token);
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      localStorage.removeItem('token');
      delete axiosInstance.defaults.headers.common['Authorization'];
    }
  },

  clearAuth: () => {
    localStorage.removeItem('token');
    delete axiosInstance.defaults.headers.common['Authorization'];
    window.location.href = '/login';
  }
};

// Khởi tạo token từ localStorage nếu có
const token = localStorage.getItem('token');
if (token) {
  api.setAuthToken(token);
}

export default api;