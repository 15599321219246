// components/logs/LogsViewer.jsx
import React from 'react';
import { Button } from "@tremor/react";

export const LogsViewer = ({ logs, loading, onRefresh }) => {
  const getLogLevelColor = (level) => {
    switch (level) {
      case 'error':
        return 'text-red-600';
      case 'warning':
        return 'text-yellow-600';
      case 'success':
        return 'text-green-600';
      default:
        return 'text-gray-600';
    }
  };

  const formatMetadata = (metadata) => {
    if (!metadata) return null;
    try {
      const data = typeof metadata === 'string' ? JSON.parse(metadata) : metadata;
      return (
        <div className="mt-1 pl-4 text-xs md:text-sm text-gray-500 break-all">
          {Object.entries(data).map(([key, value]) => (
            <div key={key}>
              <span className="font-medium">{key}:</span>{' '}
              <span>{typeof value === 'object' ? JSON.stringify(value) : value}</span>
            </div>
          ))}
        </div>
      );
    } catch {
      return null;
    }
  };

  return (
    <div>
      {/* Log container với responsive height */}
      <div className="bg-gray-50 rounded-md p-2 md:p-4 h-[400px] md:h-[600px] overflow-y-auto font-mono text-xs md:text-sm">
        {Array.isArray(logs) && logs.length > 0 ? (
          logs.map((log, index) => (
            <div 
              key={`${log.timestamp}-${index}`} 
              className={`py-2 border-b last:border-0 ${getLogLevelColor(log.level)}`}
            >
              {/* Log header with timestamp */}
              <div className="flex flex-col md:flex-row md:justify-between md:items-start gap-1">
                <div className="flex flex-col md:flex-row md:items-center gap-1 md:gap-2">
                  <span className="text-gray-500 whitespace-nowrap">
                    {new Date(log.timestamp).toLocaleString()}
                  </span>
                  <span className="font-semibold">[{log.level.toUpperCase()}]</span>
                </div>
              </div>
              
              {/* Log message with word wrap */}
              <div className="mt-1 break-words whitespace-pre-wrap">
                {log.message}
              </div>

              {/* Metadata with better formatting */}
              {formatMetadata(log.metadata)}
            </div>
          ))
        ) : (
          <div className="text-center text-gray-500 py-4">
            {!Array.isArray(logs) ? 'Error loading logs' : 'No logs to display'}
          </div>
        )}
      </div>
    </div>
  );
};

export default LogsViewer;